import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["photo", "input"];
  connect() {
  }

  update(){
    if (this.inputTarget.files && this.inputTarget.files[0]) {

      let file = this.inputTarget.files[0];

      const allowedFormats = ['image/jpeg', 'image/png', 'image/gif'];
      if (!allowedFormats.includes(file.type)) {
        this.showError("Format de fichier non pris en charge. Formats acceptés: JPG, PNG.")
        this.inputTarget.value = '';
        return;
      }

      // Vérifier la taille du fichier
      const maxSizeInBytes = 1048576; // 1 Mo
      if (file.size > maxSizeInBytes) {
        this.showError("La taille de l'image dépasse la limite autorisée de 1 Mo.")
        this.inputTarget.value = '';
        return;
      }

      this.removeError();

      var reader = new FileReader();

      reader.onload = function (e) {
        document.getElementById('preview-photo').setAttribute('src', e.target.result);
      };

      reader.readAsDataURL(this.inputTarget.files[0]);
    }
  }

  showError(message){
    let parent = this.inputTarget.parentElement;
    let p = document.createElement('p');
    p.classList = "mt-2 text-sm text-red-600 dark:text-red-500";
    p.innerHTML = "<span class='font-medium'>Oups!</span> " + message;
    p.id = "error_picture";
    parent.appendChild(p);
  }

  removeError(){
    let parent = this.inputTarget.parentElement;
    let p = document.getElementById("error_picture");
    if (p) {
      parent.removeChild(p);
    }
  }
}