import { Controller } from "@hotwired/stimulus";
import Cropper from "cropperjs";

export default class extends Controller {
  static targets = ["input", "photo", "preview"];

  connect() {
    this.cropper = null;
  }

  update(event) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.photoTarget.src = e.target.result; // Affiche l'image dans la modal
        this.openModal();
        this.initCropper();
      };
      reader.readAsDataURL(file);
    }
  }

  initCropper() {
    if (this.cropper) {
      this.cropper.destroy(); // Réinitialise le cropper
    }
    this.cropper = new Cropper(this.photoTarget, {
      aspectRatio: 1, // Carré
      viewMode: 1,
    });
  }

  crop() {
    if (this.cropper) {
      const canvas = this.cropper.getCroppedCanvas({
        width: 512,
        height: 512,
      });
      canvas.toBlob((blob) => {
        const file = new File([blob], "cropped_image.jpg", {
          type: "image/jpeg",
        });
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        this.inputTarget.files = dataTransfer.files;

        // Ferme la modal après le recadrage
        this.closeModal();
      }, "image/jpeg");

      const croppedImageUrl = canvas.toDataURL("image/jpeg");

      const previewPhoto = this.previewTarget;
      if (previewPhoto) {
        previewPhoto.src = croppedImageUrl; // Mettre à jour la source de l'image
      }
    }
  }

  openModal() {
    document.getElementById("cropperModal").classList.remove("hidden");
  }

  closeModal() {
    document.getElementById("cropperModal").classList.add("hidden");
  }
}
